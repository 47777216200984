.item{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.itemfull{
    background: white;
    padding: 10px;
    border-radius: 20px;
}
.image{
    border-radius: 20px;
}

.item p{
    margin: 6px 0;
    font-size: 17px;

}
.item-prices{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.part-1{
    display: flex;
     gap: 20px;
}
.item-price-new{
    color: #374151;
    font-size: 18px;
    font-weight: 600;
}
.item-price-old{
    color: #8c8c8c;
    font-size: 18px;
    font-weight: 500;
}
.card{
    max-width: 800px;
    margin: 20px auto;
    border-radius: 30px;
    background: #fce8cc;
}
.add-to-cart{}
@media(max-width:1280px){
    .item{
        width: 220px;
        font-size: 14px;
    }
    .item img{
        width: 220px;
    }
    .item-price-old{
        font-size: 14px;
    }
}
@media (max-width: 1024px){
    .item{
        width: 170px;
        font-size: 13px;
    }
    .item img{
        width: 170px;
    }
    .item-price-old{
        font-size: 16px;
    }
}
@media (max-width: 1024px){
    .item{
        width: 170px;
        font-size: 13px;
    }
    .item img{
        width: 170px;
    }
    .item-price-old{
        font-size: 16px;
    }
}

@media (max-width: 800px){
    .item{
        width: 120px;
        font-size: 12px;
    }
    .item img{
        width: 120px;
    }
    .item-price-old{
        font-size: 12px;
    }
}
@media(max-width: 500px){
    .item{
        width: 160px;
    }
    .item img{
        width: 160px;
    }
}