@tailwind base;
@tailwind components;
@tailwind utilities;


@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,900&display=swap");


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: #506d18 #faefe0;
  scroll-behavior: smooth;
}
body {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #faefe0;
  overflow-x: hidden;
  color: var(--fontPrimaryColor);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.link-styles {
  text-decoration: none;
  color: #3a3a3a;
}
.btn-link-styles {
  background: white;
  color: #8D6E63;
}

:root {
  --primaryColor: #506d18;
  --primaryColorDark: #506d18;
  --primaryColorLight: #73bbc9b6;
  --secondaryColor: #f1d4e5;
  --primaryBackgroundColor: #ececec;
  --secondaryBackgroundColor: #dadada;
  --fontPrimaryColor: #212121;
  --fontSecondaryColor: #616161;
  --navbarBg: #388E3C;
  --backgroundGradient: radial-gradient(
    circle at 10% 45%,
    #506d18 2%,
    #faefe0 25%
  );
  --backgroundGradient2: radial-gradient(
    circle at 90% 45%,
    #506d18 2%,
    #faefe0 25%
  );
  --backgroundGradient3: radial-gradient(
    circle at 2% 40%,
    #506d18 2%,
    #faefe0 13%
  );
  --backgroundGradient4: radial-gradient(
    circle at 92% 25%,
    #506d18 2%,
    #faefe0 10%
  );
  --gradientBackground: -webkit-linear-gradient(
    109.6deg,
    rgb(255, 235, 59) 41.2%,
    rgb(80, 109, 24) 100.2%
  );
  --gradientBackground2: -webkit-linear-gradient(
    109.6deg,
    rgb(248, 224, 9) 41.2%,
    rgb(56, 142, 60) 71.2%,
    rgb(80, 109, 24) 100.2%
  );
  --gradientBackground3: -webkit-linear-gradient(
    109.6deg,
    rgb(255, 255, 255) 41.2%,
    rgb(223, 234, 250) 100.2%
  );
}

::-webkit-scrollbar {
  width: 0.7em;
  background-color: rgb(224, 247, 236);
}
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgb(224, 247, 236);
  border-radius: 5px;
}
body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--primaryColor);
}
body::-webkit-scrollbar-thumb:hover {
  background-color: rgb(80, 109, 24);
}


@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 142.1 76.2% 36.3%;
    --primary-foreground: 355.7 100% 97.3%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 142.1 76.2% 36.3%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 20 14.3% 4.1%;
    --foreground: 0 0% 95%;
    --card: 24 9.8% 10%;
    --card-foreground: 0 0% 95%;
    --popover: 0 0% 9%;
    --popover-foreground: 0 0% 95%;
    --primary: 142.1 70.6% 45.3%;
    --primary-foreground: 144.9 80.4% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 15%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 12 6.5% 15.1%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 142.4 71.8% 29.2%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}


